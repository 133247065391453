import { $notify, getMessageFromError } from "@/plugins/Helpers";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        userUpdate: {},
    },
    actions: {
        changePassword: ({ rootState }, payload) => {
            payload.email = rootState.auth.user.email;

            return axios
                .patch(`/user/password-set/`, payload)
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        twoStepVerification: ({ rootState, commit }, payload) => {
            return axios
                .put(`/user/update/${rootState.auth.user.id}/`, payload)
                .then((res) => {
                    commit("setUserUpdate", res);
                    return res;
                });
        },

        userProfileUpdate: ({ rootState }, payload) => {
            return axios
                .put(`/user/update/${rootState.auth.user.id}/`, payload)
                .then((res) => {
                    return res;
                });
        },

        changeEmail: ({ commit }, payload) => {
            return axios
                .post(`/user/email-token-create/`, payload)
                .then((res) => {
                    $notify.success(
                        "A verification token has been sent to your new email."
                    );
                    commit(
                        "temp/setData",
                        {
                            email: payload.email,
                            type: "update-email",
                        },
                        { root: true }
                    );
                    router.push({ name: "email-verification" });
                    return res;
                })
                .catch(() => {
                    $notify.error("This email has been already registered");
                });
        },

        verifyEmail: ({ rootState, dispatch }, token = null) => {
            const payload = {
                email: rootState.temp.data?.email,
                token: token,
            };
            return axios
                .put(`/user/email-update/`, payload)
                .then(() => {
                    $notify.success(
                        "Please login again with new email.",
                        "Email changed successfully"
                    );
                    dispatch("auth/logout", null, { root: true });
                    router.push({ name: "login" });
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        deactivateAccount: ({ rootState, dispatch }) => {
            const id = rootState.auth.organization.id;
            return axios
                .put(`/organization/deactivate-request/profile/${id}/`)
                .then((res) => {
                    $notify.success(res.details);
                    dispatch("auth/logout", null, { root: true });
                    router.push({ name: "login" });
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        removeAccount: ({ rootState }) => {
            const id = rootState.auth.organization.id;
            return axios
                .put(`/organization/delete-request/profile/${id}/`)
                .then((res) => {
                    $notify.success("Organization delete request sended");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        changeOwnership: ({ rootState }, payload) => {
            return axios
                .post(
                    `/user/transfer-ownership/${rootState.auth.organization.organization}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("ownership transfer successfully");
                    return res;
                });
        },
        ownershipTransferOrg: ({ rootState }, payload) => {
            // console.log("puyaload", payload);
            console.log("rootState", rootState);
            return axios
                .post(
                    `/organization/set-new-owner-info/${payload.organization}/`,
                    payload
                )
                .then(() => {
                    $notify.success("ownership set successfully");
                    return router.push({ name: "login" });
                });
        },
    },
    mutations: {
        setUserUpdate: (state, payload) => {
            state.userUpdate = payload;
        },
        reset: (state) => {
            state.userUpdate = {};
        },
    },
};

import { notify } from "@kyvg/vue3-notification";
import router from "@/router";
import { $notify, getMessageFromError } from "@/plugins/Helpers";
import { store } from "@/store";

export default {
    state: {
        user: null,
        isLoggedIn: false,
        tokenTTL: 0,
        token: null,
        organization: null,
        organizationId: "",
        refreshTokenInterval: null,
        permissions: null,
        isRefreshing: false,
        check_user: {},
        organizationName: null,
        confirmOwnershipDetails: {},
    },
    actions: {
        login: ({ commit, dispatch }, payload) => {
            return axios
                .post("/user/token-auth/", payload)
                .then(async (response) => {
                    if (response.token) {
                        commit("setToken", response.token);
                    } else {
                        return response;
                    }
                    await dispatch("generateRefreshTokenLoop");
                    await dispatch("fetchUser");
                    return response;
                });
        },

        checkUser: ({ commit }, payload) => {
            return axios
                .post("/user/get-user-type/", payload)
                .then((res) => {
                    commit("setCheckedUser", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        googleLogin: ({ dispatch, commit }, payload) => {
            commit(
                "temp/setData",
                {
                    email: payload.email,
                    name: payload.name,
                },
                { root: true }
            );

            commit("setToken", payload.token);
            dispatch("generateRefreshTokenLoop");
        },

        registration: ({ commit }, payload) => {
            return axios
                .post("/user/email-token-create/", payload)
                .then((res) => {
                    commit(
                        "temp/setData",
                        {
                            user_full_name: payload.owner_name,
                            name: payload.organization_name,
                            email: payload.email,
                            type: "email",
                        },
                        { root: true }
                    );
                    return res;
                });
        },

        sendPasswordRestRequest: ({ commit }, payload) => {
            return axios.patch("/user/password-reset/", payload).then((res) => {
                commit(
                    "temp/setData",
                    { email: payload.email, type: "token" },
                    { root: true }
                );
                return res;
            });
        },

        emailTokenCreate: ({ rootState }) => {
            let url = "/user/password-reset/";
            let type = "patch";

            if (
                rootState.temp.data?.type === "email" ||
                rootState.temp.data?.type === "update-email"
            ) {
                url = "/user/email-token-create/";
                type = "post";
            }

            const data = { email: rootState.temp.data?.email };
            return axios[type](url, data).then((res) => {
                return res;
            });
        },

        tokenVerification: ({ rootState, commit }, token) => {
            const data = {
                email: rootState.temp.data?.email,
                token: token,
            };

            let url = "/user/forgot-password-token-verify/";

            if (rootState.temp.data?.type === "email") {
                url = "/user/email-token-verify/";
            }

            return axios.put(url, data).then((res) => {
                commit(
                    "temp/setData",
                    { ...rootState.temp.data, ...{ token } },
                    { root: true }
                );
                return res;
            });
        },

        setPassword: ({ commit, rootState }, payload) => {
            payload = { ...payload, ...rootState.temp.data };

            let url = "/user/password-set/";
            let type = "put";

            if (rootState.temp.data?.type === "email") {
                url = "/organization/create/";
                type = "post";
            }
            return axios[type](url, payload).then(() => {
                notify({
                    title: "Password set successfully.",
                    text: "Please login to continue.",
                    type: "success",
                    duration: 10000,
                });
                commit("temp/clearData", null, { root: true });
                return router.push({ name: "login" });
            });
        },

        fetchUser: ({ commit }) => {
            return axios
                .get("/user/details/")
                .then((res) => {
                    const {
                        organization_profiles,
                        access_expiration_delta,
                        ...userData
                    } = res;
                    commit("setUser", {
                        ...userData,
                        ...organization_profiles[0]?.user_info,
                    });
                    commit("setTokenTTL", access_expiration_delta);

                    // organizationId
                    organization_profiles.map(async (org) => {
                        if (
                            org.organization &&
                            org.organization === store.state.auth.organizationId
                        ) {
                            await commit("setOrganization", org);
                            await commit("setPermissions", org?.permissions);
                        } else {
                            commit("setOrganization", organization_profiles[0]);
                            await commit(
                                "setPermissions",
                                organization_profiles[0]?.permissions
                            );
                        }
                    });
                    // commit("setOrganization", organization_profiles[0]);

                    commit(
                        "setOrganizationName",
                        organization_profiles[0]?.name
                    );
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        createOrganization: ({ commit }, payload) => {
            return axios
                .post("/user/social-auth/organization-create/", payload)
                .then(() => {
                    commit("temp/clearData", null, { root: true });
                });
        },

        refreshToken: ({ commit, state, dispatch }) => {
            return axios
                .post(
                    `/user/token/refresh/`,
                    {
                        refresh: state.token.refresh,
                    },
                    { Authorization: null }
                )
                .then((res) => {
                    commit("setToken", res);
                    dispatch("generateRefreshTokenLoop");
                })
                .catch(() => {
                    dispatch("localLogout");
                    $notify.error("Session Expired, Please login again.");
                });
        },

        generateRefreshTokenLoop: ({ state, dispatch, commit }) => {
            commit("clearRefreshTokenLoop");
            // refresh token interval
            state.refreshTokenInterval = setInterval(async () => {
                await dispatch("refreshToken");
            }, 270000);
            return state.refreshTokenInterval;
        },

        destroyToken: ({ state, dispatch }) => {
            if (!state.token || !state.token.refresh) {
                dispatch("localLogout");
            }
            return axios
                .post(`/user/token-invalidate/`, {
                    refresh_token: state.token.refresh,
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },

        localLogout: async ({ dispatch, state, commit }) => {
            clearInterval(state.refreshTokenInterval);
            commit("reset");
            dispatch("clearStore", null, { root: true });
            axios.defaults.headers.common["Authorization"] = null;

            await router.push("/login");
        },

        logout: async ({ dispatch }) => {
            await dispatch("destroyToken")
                .then(() => {
                    dispatch("localLogout");
                })
                .catch((err) => {
                    getMessageFromError(err);
                });
        },

        ownershipConfirm: ({ commit }, payload) => {
            return axios
                .post(
                    `organization/confirm-new-owner-info/${payload.org_id}/`,
                    payload
                )
                .then(async (res) => {
                    commit("setOwnershipConfirmation");
                    $notify.success(res.details);
                    await router.push({ name: "login" });
                    return res;
                })
                .catch((err) => {
                    router.push({ name: "login" });
                    getMessageFromError(err);
                });
        },
    },
    mutations: {
        setUser: (state, data) => {
            state.user = data;
            state.user.full_name = "";

            if (state.user.prefix_name) {
                state.user.full_name += state.user.prefix_name + " ";
            }
            if (state.user.first_name) {
                state.user.full_name += state.user.first_name + " ";
            }
            if (state.user.middle_name) {
                state.user.full_name += state.user.middle_name + " ";
            }
            if (state.user.last_name) {
                state.user.full_name += state.user.last_name;
            }
        },
        setCheckedUser: (state, data) => {
            state.check_user = data;
        },
        clearUser: (state) => {
            state.user = {};
        },
        setTokenTTL: (state, ttl) => {
            state.tokenTTL = ttl;
        },
        clearTokenTTL: (state) => {
            state.tokenTTL = 0;
        },
        setOrganization: (state, organization) => {
            state.organization = organization;
        },
        setOrganizationId: (state, payload) => {
            state.organizationId = payload;
        },
        clearOrganization: (state) => {
            state.organization = null;
        },
        setPermissions: (state, permissions) => {
            state.permissions = permissions;
        },
        clearPermissions: (state) => {
            state.permissions = null;
        },
        setToken: (state, tokens) => {
            state.token = tokens;
            axios.defaults.headers.common[
                "Authorization"
            ] = `JWT ${tokens.access}`;
            state.isLoggedIn = !!tokens.access;
        },
        setIsRefreshing: (state, value) => {
            state.isRefreshing = value;
        },
        clearRefreshTokenLoop: (state) => {
            clearInterval(state.refreshTokenInterval);
        },
        setRefreshTokenInterval: (state, data) => {
            state.refreshTokenInterval = data;
        },
        setOwnershipConfirmation: (state, data) => {
            state.confirmOwnershipDetails = data;
        },
        reset: (state) => {
            state.user = null;
            state.isLoggedIn = false;
            state.tokenTTL = 0;
            state.token = null;
            state.organization = null;
            state.refreshTokenInterval = null;
            state.permissions = null;
            state.check_user = {};
            state.organizationName = null;
        },
        setOrganizationName: (state, data) => {
            state.organizationName = data;
        },
    },
};

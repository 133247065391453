import { $setParams, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};
export default {
    namespaced: true,
    state: {
        settings: {},
        teams: [],
        team: {},
    },

    actions: {
        fetch: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/peoples/team/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setTeams", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchDashboardPeople: ({ rootState }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/dashboard/team/all/${$setParams(params)}`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchSettings: ({ rootState, state, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/peoples/setting/`
                )
                .then((res) => {
                    commit("setSettingsData", res);
                    return state.settings;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        create: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/peoples/team/create/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        update: ({ rootState, state }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/peoples/team/edit/${state.team.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        sendInvitation: ({ rootState, state }, payload) => {
            payload.using_application = true;
            payload.people = state.team.id;

            return axios
                .post(
                    `/${rootState.auth.organization.organization}/peoples/team/invite/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadPeopleTeam: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/peoples/team/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadPeopleTeamFile: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/peoples/team/upload/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setTeams: (state, payload) => {
            state.teams = payload;
        },
        clearTeams: (state) => {
            state.teams = [];
        },
        setTeam: (state, payload) => {
            state.team = payload;
        },
        clearTeam: (state) => {
            state.team = {};
        },
        setSettingsData: (state, payload) => {
            payload.document_types_mapping = JSON.parse(
                payload.document_types_mapping
            );
            payload.team_fields_mapping = JSON.parse(
                payload.team_fields_mapping
            );
            state.settings = payload;
        },
        reset: (state) => {
            state.settings = {};
            state.teams = [];
            state.team = {};
        },
    },
};

import { $notify, getMessageFromError } from "../../plugins/Helpers";
export default {
    namespaced: true,
    state: {
        widgets: [],
    },

    actions: {
        fetchWidgets: ({ rootState, commit } = {}) => {
            // params = { ...$defaultParams, ...params };
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/dashboard/widget/all/`
                )
                .then(async (response) => {
                    await commit("setWidgets", response.results);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchWidgetConfig: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/dashboard/widget/config/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        widgetGenerate: ({ rootState }, { payload, action }) => {
            // action: generate/save
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/dashboard/widget/data/?action=${action}`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch(() => {
                    $notify.error("Unable to bind data", "error");
                });
        },
    },

    mutations: {
        setWidgets: (state, payload) => {
            state.widgets = payload;
        },
    },
};

import { Auth, Guest, SocialAuth } from "@/middleware/auth";
import { store } from "@/store";

export default [
    {
        path: "/",
        component: () =>
            import(/* webpackChunkName: "auth" */ "@/layouts/Auth"),
        meta: {
            title: "Quantibly | Auth",
            script: [
                {
                    type: "text/javascript",
                    src: "https://apis.google.com/js/api:client.js",
                    async: true,
                    body: true,
                },
            ],
        },
        children: [
            {
                path: "/login",
                name: "login",
                meta: {
                    title: "Quantibly | Login",
                },
                beforeEnter: Guest,
                component: () =>
                    import(/* webpackChunkName: "auth" */ "@/views/auth/login"),
            },
            {
                path: "/set-organization",
                name: "set-organization",
                meta: {
                    title: "Quantibly | Organization create",
                },
                beforeEnter: SocialAuth,
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "@/views/auth/set-organization"
                    ),
            },
            {
                path: "/registration",
                name: "registration",
                beforeEnter: Guest,
                meta: {
                    title: "Quantibly | Sign up",
                },
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "@/views/auth/registration"
                    ),
            },
            {
                title: "Quantibly | Two-Factor Authentication",
                path: "/email-verification",
                name: "email-verification",
                meta: {
                    title: "Quantibly | Two-Factor Authentication",
                },
                beforeEnter: () => {
                    if (store.state.temp?.data?.type === "update-email") {
                        return Auth;
                    }
                    return Guest;
                },
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "@/views/auth/email-verification"
                    ),
            },
            {
                path: "/set-password",
                name: "set-password",
                meta: {
                    title: "Quantibly | Set Password",
                },
                beforeEnter: Guest,
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "@/views/auth/email-verification/set-password"
                    ),
            },
            {
                path: "/forget-password",
                name: "forget-password",
                meta: {
                    title: "Quantibly | Reset Password",
                },
                beforeEnter: Guest,
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "@/views/auth/forget-password"
                    ),
            },
            {
                path: "/ownership-confirmation",
                name: "ownership-confirmation",
                meta: {
                    title: "Quantibly | Confirmation",
                },
                beforeEnter: (to, next) => {
                    if (!to.query.organization) {
                        next();
                    }
                },
                component: () =>
                    import(
                        /* webpackChunkName: "auth" */ "@/views/auth/ownership-confirmation"
                    ),
            },
        ],
    },
];

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import user from "./modules/user";
import generics from "./modules/generics";
// organization
import organizationProfile from "./modules/organization/profile";
import organizationServices from "./modules/organization/services";
import organizationTeams from "./modules/organization/teams";
import organizationLocations from "./modules/organization/locations";
import organization from "./modules/organization";
// people
import peopleSettings from "./modules/people/settings";
import peopleTeams from "./modules/people/teams";
import peopleInsights from "./modules/people/insights";
// partnership
import partnershipSettings from "./modules/partnership/settings";
import partnershipsPartners from "./modules/partnership/partners";
import partnershipsExperts from "./modules/partnership/outsideExperts";
import partnershipsInsight from "./modules/partnership/insight";
// programs-and-services
import programsServicesSettings from "./modules/programs-and-services/settings";
import programsServicesProgramActivity from "./modules/programs-and-services/programActivity";
import programsServicesPartners from "./modules/programs-and-services/partners";
import programsServicesFormBuilder from "./modules/programs-and-services/formBulder";
import programsServicesDataForm from "./modules/programs-and-services/dataForm";
import programServicesInsights from "./modules/programs-and-services/insights";
import dashboard from "./modules/dashboard";
// others
import accountSettings from "./modules/accountSettings";
import stripe from "./modules/stripe";
import google from "./modules/google";
import dropbox from "./modules/dropbox";
import documents from "./modules/documents";
import temp from "./modules/temp";
// user manual
import handbook from "./modules/handbook";

// finance
import financeRevenue from "./modules/finance/revenue";
import financeExpense from "./modules/finance/expense";
import financeSettings from "./modules/finance/settings";

//import financeTeams from "./modules/finance/teams";
//import financeInsights from "./modules/finance/insights";
import financeInsight from "./modules/finance/insight";

// fundraising
import fundraisingInsights from "./modules/fundraising/insights";
import fundraisingFoundations from "./modules/fundraising/foundations";
import fundraisingGovernment from "./modules/fundraising/government";
import fundraisingIndividuals from "./modules/fundraising/individuals";
import fundraisingSettings from "./modules/fundraising/settings";
import fundraisingCorporation from "./modules/fundraising/corporation";

// reporting
import reporting from "./modules/reporting/reporting.js";

// my profile
import myProfile from "./modules/myProfile";

// governance
import governanceSettings from "./modules/governance/settings";
import governanceCommittees from "./modules/governance/committees";
import governanceCompliance from "./modules/governance/compliance";
import governanceGoverningTeam from "./modules/governance/governingTeam";
import governanceInsights from "./modules/governance/insights";

//settings
import settingsInsight from "./modules/settings/insight";
import chart from "./chart";
//support
import support from "./modules/support/index";
// widget
import widget from "./modules/widget";
const modules = {
    auth,
    user,
    generics,
    organization,
    organizationProfile,
    organizationServices,
    organizationTeams,
    organizationLocations,
    peopleSettings,
    partnershipSettings,
    peopleTeams,
    peopleInsights,
    stripe,
    financeRevenue,
    financeExpense,
    financeSettings,
    temp,
    partnershipsPartners,
    partnershipsExperts,
    accountSettings,
    programsServicesSettings,
    programsServicesProgramActivity,
    programsServicesPartners,
    partnershipsInsight,
    programsServicesFormBuilder,
    programsServicesDataForm,
    programServicesInsights,
    dashboard,
    handbook,
    google,
    dropbox,
    documents,
    financeInsight,
    fundraisingInsights,
    fundraisingFoundations,
    fundraisingGovernment,
    fundraisingIndividuals,
    fundraisingSettings,
    fundraisingCorporation,
    reporting,
    myProfile,
    governanceSettings,
    governanceCommittees,
    governanceCompliance,
    governanceGoverningTeam,
    governanceInsights,
    settingsInsight,
    support,
    chart,
    widget,
};

Object.keys(modules).forEach((key) => {
    modules[key].namespaced = true;
});

const store = createStore({
    modules,
    actions: {
        clearStore: ({ commit }) => {
            Object.keys(modules).map((key) => {
                commit(`${key}/reset`);
            });
        },
    },
    plugins: [createPersistedState()],
});

export { store, modules };
